/**
 * HOC that checks if the currently logged in user
 * has the necessary roles to view the wrapped
 * component. If not, it redirects to /404.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import AuthenticationWrapper from './AuthenticationWrapper';
import gate from './gate';

const AuthorizationWrapper = allowedRoles => WrappedComponent => {
  class WithAuthorization extends React.Component {
    static propTypes = {
      me: PropTypes.shape({
        id: PropTypes.string.isRequired,
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }),
        ),
      }).isRequired,
    };

    render() {
      if (gate.isAllowed({ user: this.props.me, allowedRoles })) {
        return <WrappedComponent {...this.props} />;
      }
      return <Redirect to="/404" />;
    }
  }

  return AuthenticationWrapper(WithAuthorization);
};

export default AuthorizationWrapper;
