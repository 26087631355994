const booleanNormalizer = value => {
  if (value === 'true') {
    return true;
  } else if (value === 'false') {
    return false;
  }
  return value;
};

const optionsNormalizer = (value, previousValue) => {
  if (typeof value === 'string' && value.length === 0) {
    return null;
  }
  if (value || value === null) {
    return value;
  }
  return previousValue;
};

export { booleanNormalizer, optionsNormalizer };
